import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import IO from 'components/io';
import Layout from 'components/layout';
import { StickAnimation } from 'components/layout/layout.css';
import Title from 'components/title';
import Spacer from 'components/spacer';
import { animationOffset } from 'constants/theme';

import Stick404 from './assets/stick-404.svg';

const NotFound = ({ data }) => (
  <Layout>
    <section className="jumbotron">
      <IO rootMargin={animationOffset}>
        {({ isVisible }) => (
          <StickAnimation isVisible={isVisible}>
            <Stick404 />
          </StickAnimation>
        )}
      </IO>

      <Spacer />
      <Title as="h2" weight="light">
        {data.errorJson.title}
      </Title>
      <Spacer />

      <p className="lead">
        {data.errorJson.text}
        <br />
        <span className="muted">{data.errorJson.error}</span>
      </p>

      <Spacer />
      <p>
        <Link to="/" className="btn btn-primary">
          {data.errorJson.button}
        </Link>
      </p>
    </section>
  </Layout>
);

NotFound.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NotFound;

export const query = graphql`
  query ErrorQuery {
    errorJson {
      title
      text
      error
      button
    }
  }
`;
